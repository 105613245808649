import React from "react"
import { Row, Col } from "react-flexbox-grid"

import Layout from "../../components/Layout"
import HelmetWrapper from "../../components/HelmetWrapper"
import Hero from "../../components/Hero"
import ContentBlock from "../../components/ContentBlock"

import aboutUsImg from "../../assets/images/HBS-102-Icon-FNL_2.png"
import headerHero from "../../assets/images/header_aboutus_2.jpg"

const pageTitle = "About | Harmony Biosciences"
const pageDescription =
  "Harmony Biosciences is focused on developing innovative medicines for rare neurological and unmet medical needs. Patients are at the heart of everything we do."
const pageKeywords = "rare diseases - 12,100"

const About = () => {
  return (
    <Layout jobCode="US-HAR-2200050/Jun 2022">
      <div id="overview">
        <HelmetWrapper
          pageTitle={pageTitle}
          pageDescription={pageDescription}
          pageKeywords={pageKeywords}
        />
        <Hero bg={headerHero}>
          <h1>Overview</h1>
        </Hero>
        <ContentBlock color="blue">
          <Row>
            <Col xs={12} md={2}>
              <Row center="xs">
                <Col>
                  <img src={aboutUsImg} className="" />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={10}>
              <p>
                At <strong>Harmony Biosciences</strong>, we specialize in
                developing and delivering treatments for rare neurological
                diseases that others often overlook. We believe that when
                empathy and innovation meet, a better life can begin for people
                living with neurological diseases. Our team of experts from a
                wide variety of disciplines and experiences is driven by our
                shared conviction that innovative science translates into
                therapeutic possibilities for patients, who are at the heart of
                everything we do.
              </p>
            </Col>
          </Row>
        </ContentBlock>
      </div>
    </Layout>
  )
}

export default About
